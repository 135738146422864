.list-campaign {
    margin: 40px;
    padding: 30px;
}

.message-resume {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.message-resume > * {
    margin-left: 10px;;
}