.container-page {
    margin: 40px 40px 40px 40px;
    width: 100%;
    height: 100%;
}

.create-template {
    padding: 30px;
}

.templates-edition-button-bar {
    display: flex;
    justify-content: left;
    align-items: left;
}

.templates-button-bar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.templates-button-bar :nth-child(1) {
    margin-right: 10px;
}

.radio-button {
    margin-bottom: 2px;
}

.radio-button-icon{
    margin-left: 5px;
    margin-right: 5px;
}

.template-form > *{
    margin-bottom: 10px;
    width: 100%;
}

.template-form-submit{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview-text-area{
    width: 100%;
    height: 150px;
    border: 1px solid rgb(210, 223, 230);
    border-radius: 8px;
}

.quick-reply-button{
    background-color: unset;
    border: unset;
    outline: none;
    margin: 0px 8px 4px;
    color: rgb(32, 44, 68);
    font-family: "Nunito Sans", Tahoma, Helvetica, Arial, sans-serif;
}

.quick-reply-close-button {
    cursor: pointer;
}

.quick-reply-buttons-bar{
    margin-top: 15px;
    display: flex;
    max-width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.quick-reply-chip{
    margin-bottom: 10px;
    margin-left: 10px;
}