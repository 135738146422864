.status-banner {
    position: fixed;
    top: 0;
    display: flex;
    z-index: 1000;
}


.status-banner-text{
    align-self: center;
}