.report-problem-button{
    width: 50px;
    height: 50px;
    background-color: gainsboro;
    border-radius: 50px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.report-problem-button:hover{
    background-color: gray;
    color:white;
    cursor: pointer;
}

.report-problem-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 300px;
    background-color: white;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 10px 10px 20px 0px rgb(0 0 0 / 75%);
}

.report-problem-message{
    width: 100%;
    margin-bottom: 15px;
}

.report-problem-modal-buttons{
    float: right;
}