.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.vertical-bar {
  border-left: 2px dotted #1a2437;
}

.menu {
  padding: 20px;
}

.menu-grid {
  width: 100%;
  height: 100%;
}

.modelo-mensagem {
  padding: 25px;
  width: 300px;
}

.button-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  margin-right: 5px;
}