html {
    height: 100%;
}

body {
    background-color: #f3f6fa;
    height: 100%;
}

#root {
    height: 100%;
}

a {
    text-decoration: none;
}

.MuiFormHelperText-root {
    margin-top: 0;
    height: 0;
}