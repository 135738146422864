.list-template {
    margin: 40px;
    padding: 30px;
}

.buttons-bar-quick-reply{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.buttons-quick-reply {
    margin-top: 5px;
}

.rejected-status-banner{
    margin-bottom: 15px;
}

