.create-campaign {
    margin: 40px;
    padding: 30px;
}

.select-full {
    margin-top: 10px !important;
    width: 90%;
}

.select-half {
    margin-top: 10px !important;
    width: 95%;
}

.preview-message {
    padding: 10px;
}

.public-variables-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.number-variable-line{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 30px;
    width: 500px;
}

.variables-line{
    margin-left: 5px !important;
}

.phonenumber-variables{
    overflow: auto;
    max-height: 250px;
}

.preview-message-container {
    display: flex;
    justify-content: center;
}

.section-title {
    margin-top: 55px
}

.title-helper {
    display: inline-flex;
    justify-content: space-evenly;
    align-items: baseline;
}

.helper {
    background-color: lavender;
    border-radius: 21px;
    width: 21px;
    text-align: center;
    margin-left: 5px;
    border: 1px solid;
}

.helper:hover{
    background-color: gray;
}